import React, { FC } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { IPage, IPageArticle } from 'interfaces/page';

import Head from 'components/Layout/Head/Head';
import Wiki from 'components/blocks/Wiki/Wiki';

const query = graphql`
  query {
    page: strapiTermsOfUsePage {
      meta {
        ...StrapiMetaFragment
      }
      data {
        ...StrapiArticleFragment
      }
    }
  }
`;

interface ITermsOfUsePage extends IPage {
  data: IPageArticle;
}

interface IQueryTermsOfUse {
  page: ITermsOfUsePage;
}

const TermsOfUsePage: FC<IPageProps> = () => (
  <StaticQuery
    query={query}
    render={(data: IQueryTermsOfUse) => {
      const siteInfo = data.page.meta;
      const { article, title, preface } = data.page.data;

      return (
        <>
          <Head
            title={siteInfo.title}
            description={siteInfo.description}
            slug="terms"
          />
          <Wiki
            title={title}
            text={article}
            description={preface?.[0]?.text}
          />
        </>
      );
    }}
  />
);

export default TermsOfUsePage;
