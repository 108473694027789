import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

import { Article as UIArticle } from 'UI';

const Article = styled(UIArticle)`
  grid-column: 3/9;
  position: relative;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export default Article;
