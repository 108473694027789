import React, { FC } from 'react';

import { IMediaFile } from 'interfaces/media';

import { Layout } from 'UI';

import Article from './styled';

interface IWiki {
  title: string;
  text: string;
  description?: string;
  className?: string;
  files?: IMediaFile[];
}

const Wiki: FC<IWiki> = ({
  text, title, className, description = '', files, ...res
}) => (
  <Layout withPaddings className={className} {...res}>
    <Article
      text={text}
      title={title}
      description={description}
      files={files}
    />
  </Layout>
);

export default Wiki;
